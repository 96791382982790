import { Route } from 'navi'
import React from 'react'
import { Link } from 'react-navi'
import siteMetadata from '../siteMetadata'
import ArticleSummary from './ArticleSummary'
import Bio from './Bio'
import Pagination from './Pagination'
import styles from './BlogIndexPage.module.css'

interface BlogIndexPageProps {
  blogRoot: string
  pageCount: number
  pageNumber: number
  postRoutes: Route[]
  includeBio?: Boolean
}

function BlogIndexPage({
  blogRoot: blogRoot,
  pageCount,
  pageNumber,
  postRoutes,
  includeBio,
}: BlogIndexPageProps) {
  return (
    <div>
      <header>
        <h1 className={styles.title}>
          <Link href={blogRoot}>{siteMetadata.title}</Link>
        </h1>
        {includeBio && <Bio />}
      </header>
      <span className={styles.articlesList}>
        {postRoutes.map(route => (
          <span key={route.url.href}>
            <ArticleSummary blogRoot={blogRoot} route={route} />
          </span>
        ))}
      </span>
      {pageCount > 1 && (
        <Pagination
          blogRoot={blogRoot}
          pageCount={pageCount}
          pageNumber={pageNumber}
        />
      )}
      <footer className={styles.footer}>
        <div>
          <a href="./rss.xml" target="_blank" style={{ float: 'right' }}>
            RSS
          </a>
          <Link href="./about">About</Link> &bull;{' '}
          <Link href="./tags">Tags</Link> &bull;{' '}
          <a href="https://github.com/frontarm/create-react-blog">
            Bootstrap
          </a>
        </div>
      </footer>
    </div>
  )
}

export default BlogIndexPage
