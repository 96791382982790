import { oneLine } from "common-tags";
export default {
  // The blog's title as it appears in the layout header, and in the document
  // <title> tag.
  title: 'World Kaleidoscope Project - Ecuador',

  author: 'Zachary',
  description:
    oneLine`A clone of Dan Abramov's overreacted.io using create-react-app-mdx and Navi`,

  // The number of posts to a page on the site index.
  indexPageSize: 6,
}
